
import API from "../axios";

class AnalyticsService{

 Create(data) {
    API({
      method: "post",
      url: `/Analytics/SaveAnalytics`,
      data: data,
    }).then(resp => {
      console.log(resp);
    }).catch(e => {
      console.log("Error while save analytics ", e);
    });
  }
  
}
export default AnalyticsService;
 


