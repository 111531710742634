import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DataContext from "../../context/DataContext";
import SEO from "../Share/SEO";
import Feature from "../Share/Feature";
import Analytics from "../Share/Analytics";
import { colors } from "@material-ui/core";
import { FormatTextdirectionLToR } from "@material-ui/icons";

const Header = ({ showheader, showBackButton }) => {
 
  const { urlinfo, store } = useContext(DataContext);

  //document.title = store.Name;
    
  return (
    <>
      <SEO title={store.Name} description={store.Name} imageUrl={""} />
      <section
        id="topbar"
        className="d-flex align-items-center fixed-top"
        style={
          showheader ? { visibility: "visible" } : { visibility: "hidden" }
        }
      >
        <div className="container container-display">
          <div className="contact-info header-info" style={store.Email===undefined ? {visibility:"hidden"} : {visibility:"visible"}}>     
          <div className="row">
          <div className="col-mg-12">
            <i className="bi bi-phone d-flex align-items-center">
              <a onClick={event => Analytics.CreateAnalytics(event, store.StoreId, 0, Feature.ClickHeaderWhatsAppNoLink)}  href={`tel:${store.WhatsAppNumber}`}>
                {store?.WhatsAppNumber} 
              </a>
            </i>
            </div>
          {store.Email===null ||
          
        <div className="col-mg-12 mt-2">
        <i className="bi bi-envelope d-flex align-items-center"style={store.Email===null ? {visibility:"hidden"} : {visibility:"visible"}}>              
          <a  onClick={event => Analytics.CreateAnalytics(event, store.StoreId, 0, Feature.ClickHeaderEmailLink )}  href={`mailto:${store.Email}`}>{store?.Email}</a>                           
        </i>
        </div>
      }
          </div>
          </div>
          <div className="social-links d-md-flex align-items-center">
            <a
              href={`${store.FacebookUrl}`}
              target={"_blank"}
              rel={"noopener noreferrer"}
              className="facebook"
              style={
                store.FacebookUrl
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
              onClick={event => Analytics.CreateAnalytics(event, store.StoreId, 0, Feature.ClickHeaderFacebookLink )}
            >
              <i className="bi bi-facebook"></i>
            </a>
            <a
              href={`${store.InstagramUrl}`}
              target="_blank"
              rel={"noopener noreferrer"}
              className="instagram"
              style={
                store.InstagramUrl
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
              onClick={event => Analytics.CreateAnalytics(event, store.StoreId, 0, Feature.ClickHeaderInstagramLink )}
            >
              <i className="bi bi-instagram"></i>
            </a>
          </div>
        </div>
      </section>
      <header
        id="header"
        style={
          showheader ? { visibility: "visible" } : { visibility: "hidden" }
        }
        className="d-flex align-items-center fixed-top-header"
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div className="logo">
            <h1 >
              
                {store.Name}
             
            </h1>
          </div>
          <div>
            {showBackButton ? (
              <Link
                className="back-to-left d-flex align-items-center justify-content-center active"
                style={{ cursor: "pointer" }}
                to={`/${urlinfo.storeName}`}
              >
                <i class="bi bi-arrow-left-short"></i>
              </Link>
            ) : (
              <p></p>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
