import * as React from "react";
import arieotechlogo from '../../arieotechlogo.svg';
import '../../App.css';
import MartsupLOGO from '../../MartsupLOGO.svg';
import bagimage from '../../bagimage.svg';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Welcome() {
  return (
    <div>
    {/* <Header showheader={true}/> */}
  <header>
      <title>Title</title>
      {/* <!-- Required meta tags --> */}
      <meta charset="utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
      {/* <!-- Optional JavaScript --> */}
      {/* <!-- jQuery first, then Popper.js, then Bootstrap JS --> */}
      <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
          integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossorigin="anonymous"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
          integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
          crossorigin="anonymous"></script>
      <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
          integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
          crossorigin="anonymous"></script>
  
      {/* <!-- Bootstrap CSS --> */}
      <link rel="stylesheet" href="../../App.css"/>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"/>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4594017402877260"
                crossorigin="anonymous"></script>
          <ins class="adsbygoogle"
  style={{display: "block"}}
   data-ad-format="autorelaxed"
   data-ad-client="ca-pub-4594017402877260"
               data-ad-slot="1875491704"></ins>
      <script>
                  (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
 
  </header>
  
  <body>
      <div id="content">
          <div class="main">
               <div class="portfolio-wrap">
        {/* <h1>Welcome</h1> */}
      </div>
              <div class="logodiv"><img src={MartsupLOGO} alt="" class="marsuplogo"/></div>
              <div Class="imgbag"><img src={bagimage} alt="" /></div>
              <div Class="text-store">No store available... </div>
              {/* <!-- <div class=""><h2>Shopping made simple<h2></div> --> */}
          </div>
{/*     
          <div class="powerdby">
              <h4>Powered by</h4>
              <img src={arieotechlogo} class="arieotechimg"/>
          </div> */}
      </div>
  
  </body>
  <Footer />
  {/* </html> */}
  </div>






  //   <div className="products">
  //   <div class="card" style={{backgroundColor: "#e96b56" ,color: "white"}}>
  //     <div class="portfolio-wrap">
  //       <h1>Welcome to Martsup</h1>
  //     </div>

  //     {/*<h2>Please check your URL address, It must be valid and complete URL.</h2>
  //     <p>
  //      If you are facing any issue, please contact to administrator.
  //     </p>
  //      <a href="#">Go To Homepage</a> */}
  //   </div>
    
  // </div>
  
  );
}
