import { createContext, useState, useEffect } from "react";
import API from "../axios";

const DataContext = createContext({});

export const DataProvider = ({ children, sellerName, storeName }) => {
  //header data
  const [urlinfo, setUrlinfo] = useState({});
  const [store, setStore] = useState({});

  //main page data
  const [products, setproducts] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [filter, setFilter] = useState(products);
  const [categories, setcategories] = useState([]);

  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [categoryID, setCategoryID] = useState("All");

  
  useEffect(() => {
    if(storeName !== "pagenotfound"){
      getProductsData();
    }
   
  }, []);

  const getProductsData = async () => {
    setIsLoading(true);
    try {
      await API.get(
        `/Publish/getPublishData?sellerName=${sellerName}&storeName=${storeName}`
      ).then((response) => {
        const data = response.data;
        console.log("Header Data: ", data);
        
        if (data === undefined || data === '""' || data === '') {
          window.location = "/pagenotfound";
          
   
        } else {
          //let jsonResult = JSON.parse(data);
          let result = JSON.parse(data.publishData);
          setPosts(result);
          setStore(result.Store);
          setUrlinfo(data);

          console.log("Products Data : ", result);
          setproducts(result.Products);
          setFilter(result.Products);
          setcategories(result.Categories);
          setImages(result.Images);
          setVideos(result.Videos);
        }
        setIsLoading(false);
      });
      setFetchError(null);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setFetchError(error.message);
    }
  };

  return (
    <DataContext.Provider
      value={{
        urlinfo,
        setUrlinfo,
        store,
        setStore,
        products,
        setproducts,
        images,
        setImages,
        filter,
        setFilter,
        categories,
        setcategories,
        videos,
        setVideos,
        fetchError,
        setFetchError,
        isLoading,
        setIsLoading,
        search,
        setSearch,
        searchResults,             
        posts,
        setPosts,
        categoryID,
        setCategoryID
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
