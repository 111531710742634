import React from "react";

const Footer = () => {  
  
  return (
    <>
    <footer id="footer">
      <div class="container">
        <div class="copyright">
          <strong>
            <span>
              MartsUp {new Date().getFullYear()} © All Rights Reserved{" "}
            </span>
          </strong>
        </div>
        <div class="credits">
        Tech partner {" "}
          <a href={"https://arieotech.com/"} target={"_blank"} rel={'noopener noreferrer'}>
            arieotech
          </a>
        </div>
      </div>
    </footer>        
     
             
    </>
  );
};
export default Footer;
