import React from "react";
import { NavLink } from "react-router-dom";

function PageNotFound() {
  return (
    <div className="products">
      <div class="card" style={{backgroundColor: "#e96b56" ,color: "white"}}>
        <div class="portfolio-wrap">
          <h1>404</h1>
        </div>
        <h2>Oops! This Page Could Not Be Found</h2>
        <p>
          Sorry but the page you are looking for does not exist, have been
          removed. name changed or is temporarily unavailable
        </p>
        {/* <a href="#">Go To Homepage</a> */}
      </div>
    </div>
  );
}

export default PageNotFound;
