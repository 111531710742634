const Feature = {
  ClickHeaderEmailLink: "ClickHeaderEmailLink",
  ClickHeaderWhatsAppNoLink: "ClickHeaderWhatsAppNoLink",
  ClickHeaderFacebookLink: "ClickHeaderFacebookLink",
  ClickHeaderInstagramLink: "ClickHeaderInstagramLink",
  ClickProductNameLink: "ClickProductNameLink",
  ClickWhatsAppCallButton: "ClickWhatsAppCallButton",
  ClickShareOnFacebookButton: "ClickShareOnFacebookButton",
  ClickShareOnWhatsAppButton: "ClickShareOnWhatsAppButton",
  ClickShareOnTelegramButton: "ClickShareOnTelegramButton",
};
export default Feature;
