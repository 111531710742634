import AnalyticsService from "../../service/AnalyticsService";
const Analytics  = {
  
      CreateAnalytics : function (event, storeId, prodId, featureName) {
        const service = new AnalyticsService();
        //alert(storeId + " " + prodId + " " + featureName);
        var data = {
          StoreId: storeId,
          ProductId: prodId,
          FeatureName: featureName,
          FeatureId: 0,
          IpAddress: ''
        };
        service.Create(data);
    } 
  
}

export default Analytics