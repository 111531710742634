import React from 'react'
import { propTypes } from 'react-bootstrap/esm/Image';
import { Helmet } from 'react-helmet';



const SEO  = ({ title,description,imageUrl} ) =>{
  return (
    <>
   
<Helmet>
    <title>{title}</title>
    
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={imageUrl} />    
    <meta property="og:url" content={window.location.pathname + window.location.search} />
    <meta property="og:title" content={title} />
    <meta property="og:site_name" content={window.location.hostname} />
      
</Helmet>

    </>
  )
}
SEO.prototype ={
  title : propTypes.string,
  description : propTypes.string,
  imageUrl : propTypes.string,
}

export default SEO