import { useParams } from "react-router-dom";
import { useContext, useEffect,useState} from "react";
import React from "react";
import DataContext from "../../context/DataContext";
import {
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { FacebookIcon } from "react-share";
import ReactPlayer from "react-player";
import PageNotFound from "../Share/PageNotFound";

// Import Swiper React components ,
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";

import SEO from "../Share/SEO";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../Share/ScrollToTop";
import Feature from "../Share/Feature";
import Analytics from "../Share/Analytics";
import Welcome from "../Share/Welcome";
import ClipLoader from "react-spinners/ClipLoader";

const ProductDetailPage = () => {
 
     const { posts, fetchError} = useContext(DataContext);
  const { id } = useParams();
  const [Loading, setLoading] = useState(false);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    justifyContent:"center",
    marginTop:"120px"
  };

  console.log("posts", posts);

  let product = {};
  let prodImages = [];
  let categories = [];
  let prodVideo = null;
  let store = {};
  let prodDetailFlag = true;

  if (posts.Products) {

    var prods = posts.Products.filter((p) => p.ID == id);
    product = prods[0];
    prodImages = posts.Images.filter((p) => p.ProductId == id);
    categories = posts.Categories;
    var videos = posts.Videos.filter((p) => p.ProductId == id);
    prodVideo = videos[0]?.VideoPath;

    store = posts.Store;
    if (prodImages.length === 0) {
      prodDetailFlag = false;
    }
  }

  useEffect(()=>{

    setLoading(true);
    Analytics.CreateAnalytics(null, store.StoreId, id, Feature.ClickProductNameLink);
  },[]);

  function createMarkup() {
    return {
      __html: product.Description,
    };
  }

  const callSeller = () => {
    Analytics.CreateAnalytics(null, store.StoreId, id, Feature.ClickWhatsAppCallButton);
    let url = `https://wa.me/+91${store.WhatsAppNumber}?text=I am interested to buy this ${product.Name}`;
    window.open(url);
  };

  const getCategoryName = (catId) => {
    if (catId === undefined) return "";
    let catname = categories.filter((x) => x.ID == catId)[0].CategoryName;
    return catname;
  };

  function RenderProductDetail() {
    setLoading(false);
    return (
      <> 
        
        <ScrollToTop />
        <SEO
          title={store.Name}
          description={store.Name}
          imageUrl={prodImages[0]?.ImagePath}
        />
        
        <Header showheader={true} showBackButton={posts.Products} />
        {Loading ? <ClipLoader color={"#e96b56"} loading={Loading} cssOverride={override}  size={50} /> :  
        <main id="main" className="main-page-height">
          <section id="portfolio-details" className="portfolio-details">
            <div
              className="container calculateRow"
              style={
                posts.Products
                  ? { display: "block", marginTop: "120px" }
                  : { display: "none" }
              }
            >
              <div className="row gy-4">
                <div className="col-lg-8">
                  <div className="portfolio-details-slider bordercolorcss">
                    <div
                      className="align-items-center"
                      style={{ backgroundColor: "#f8f9fa" }}
                    >
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                          clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                      >
                        {prodImages.map((img, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={img.ImagePath}
                              alt=""
                              className="prod-img product-swiper-img"
                            />
                          </SwiperSlide>
                        ))}
                        {prodVideo ? (
                          <SwiperSlide key={0}>
                            <ReactPlayer
                              className="product-video"
                              url={prodVideo}
                              controls
                            />
                            {/*<iframe
                              className="product-video"
                              src={prodVideo}
                              title={product.Name}
                              frameBorder="0"
                              allowFullScreen
                            />*/}
                          </SwiperSlide>
                        ) : (
                          <p></p>
                        )}
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="portfolio-info" style={{ marginTop: "10px" }}>
                    <h3>{product.Name}</h3>
                    <ul>
                      <li
                        style={
                          product.ShowSellingPrice
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <strong>Price</strong>: ₹{product.SellingPrice}
                      </li>
                      <li
                        style={
                          product.Discount
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <strong>Discount</strong>: {Math.round(product.Discount)}%
                      </li>
                      <li
                        style={
                          product.BrandName
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <strong>Brand</strong>: {product.BrandName}
                      </li>
                      <li>
                        <strong>Category</strong>:
                        {getCategoryName(product.CategoryId)}
                      </li>
                      <li>
                        <strong>Description</strong>:<br />
                        <div dangerouslySetInnerHTML={createMarkup()} />
                      </li>
                    </ul>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <button className="inputSubmit" onClick={callSeller}>
                      <i className="bi bi-whatsapp"></i> Contact Seller
                    </button>
                    {/* <h2>Description</h2>
                      <p>{product.description}</p> */}
                  </div>
                  <div className="portfolio-description">
                    <h5>Share this product</h5>
                    <p>
                      <WhatsappShareButton
                        url={window.location}
                        title={product.Name}
                        separator=":: "
                        onClick={(event) =>
                          Analytics.CreateAnalytics(event, store.StoreId, id, Feature.ClickShareOnWhatsAppButton)
                        }
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>

                      <FacebookShareButton
                        url={window.location.href}
                        
                        quote={store.Name}
                        hashtags={"#" + store.Name}
                        className="Demo__some-network__share-button"
                        style={{ paddingLeft: "4px" }}
                        onClick={(event) =>
                          Analytics.CreateAnalytics(event, store.StoreId, id, Feature.ClickShareOnFacebookButton)
                        }
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>

                      <TelegramShareButton
                        title={"test"}
                        url={window.location}
                        hashtags={["#" + product.Name]}
                        style={{ paddingLeft: "4px" }}
                        onClick={(event) =>
                          Analytics.CreateAnalytics(event, store.StoreId, id, Feature.ClickShareOnTelegramButton)
                        }
                      >
                        <TelegramIcon size={32} round />
                      </TelegramShareButton>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row gy-4">
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4594017402877260"
                  crossOrigin="anonymous"></script>
                {/* <!-- marts --> */}
                <ins className="adsbygoogle"
                  style={{ display: "block" }}
                  data-ad-client="ca-pub-4594017402877260"
                  data-ad-slot="9497308347"
                  data-ad-format="auto"
                  data-full-width-responsive="true"></ins>
                <script>
                  (adsbygoogle = window.adsbygoogle || []).push({ });
                </script>
              </div>
            </div>
          </section>
        </main>
  }
        <Footer />
                                                             
      </>
    );
  }

  function LoadProductDetailPage() {
    if (prodDetailFlag === false || fetchError) {
      setLoading(false);
      return <PageNotFound />;
    } else {     
      return <RenderProductDetail />;
    }
  }

  return <LoadProductDetailPage />

};

export default ProductDetailPage;
