import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import DataContext from "../../context/DataContext";
import "../Main/Main.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../Share/ScrollToTop";

export default function Main() {  
 
  const {
    products,
    images,
    filter,
    setFilter,
    categories,
    categoryID,
    setCategoryID
  } = useContext(DataContext);

  
  useEffect(() => {  
   filterProduct(categoryID); 
  }, []);


  const filterProduct = (event) => {
    document.getElementById("li" + event).className = "";
    if (event === "All") {
      setFilter(products);
     setCategoryID(event);

    } else {
      const updateList = products.filter((x) => x.CategoryId === event);
      setFilter(updateList);
      setCategoryID(event);
    }
    applyCssOnFilter(event);
  };

  function FindById(idToSearch) {
    var img = null;
    var prod = products.filter((item) => item.ID === idToSearch);
    console.log("find coverpage:", prod);
    if (prod[0].CoverImageId) {
      img = images.filter((item) => item.Id === prod[0].CoverImageId);
    } else {
      img = images.filter((item) => item.ProductId === idToSearch);
    }
    return img;
  }

  function applyCssOnFilter(itemId) {
    if (document.querySelector(".filter-active") != null) {
      document
        .querySelector(".filter-active")
        .classList.remove("filter-active");
    }   
    document.getElementById("li" + itemId).classList.add("filter-active");
  }

  function GetScrollToTopButton()
  {    
    if( products.length  > 0 && filter.length > 3)
    {
      return(
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center active" ><i class="bi bi-arrow-up-short"></i></a>
      )
    }   
  }
  
  return (
    <>
    <ScrollToTop />
      <Header showheader={true} showBackButton={false} />
      <main id="main" className="main-page-height">
        <section id="portfolio" className="portfolio">
          <div className="container calculateRow">
            <div className="row rowCenter" style={{ marginTop: "140px" }}>
              <div className="col-lg-12 d-flex justify-content-center">
                <ul
                  id="portfolio-flters"
                  style={
                    filter.length > 0
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li
                    onClick={() => filterProduct("All")}
                    id="liAll"
                    className="filter-active"
                  >
                    All
                  </li>
                  {categories?.map((cat) => (
                    <li
                      onClick={() => {
                        filterProduct(cat.ID);
                      }}
                      key={cat.ID}
                      id={"li" + cat.ID}
                    >
                      {cat.CategoryName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="products">
             
              <p
                className="statusMsg"
                style={{ display: products.length === 0 ? "block" : "none" }}
              >
                No products to display.
              </p>
              {filter.map((product) => (
                <div className="card" key={product.ID} >
                  <div className="portfolio-wrap">
                    <img
                      src={FindById(product.ID)[0]?.ImagePath}
                      className="img-fluid"
                      alt={product.Name}
                      key={product.ID}
                      style={{ height: "310px", objectPosition:"top" }}
                     
                    />

                    <Link to={`${product.ID}`} >
                      <div className="portfolio-info">
                        <h4>{product.Name}</h4>
                      </div>
                    </Link>
                  </div>
                  <Link to={`${product.ID}`} className={"view-detail-btn"} >View Detail</Link>
                </div>
                
              ))}
            </div>
           
          </div>
         
        </section>
      </main>
      <Footer />
     <GetScrollToTopButton />
    </>
  );
}
