import React, { useContext, CSSProperties  } from "react";
import { Grid } from "@material-ui/core";
import Main from "../Main/Main";
import DataContext from "../../context/DataContext";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  justifyContent:"center",
  marginTop:"120px"
};
const Home = () => {
  const { fetchError, isLoading } = useContext(DataContext);  
  
  return (
    <>     

      <Grid container>
        <Grid item sm={12} xs={12}>
    {isLoading ? <ClipLoader color={"#e96b56"} loading={isLoading} cssOverride={override}  size={50} /> : <Main/>}
        {/* {isLoading === true && <ClipLoader color={"red"} loading={isLoading}  size={100} />}
         {!isLoading && fetchError && <p className="statusMsg" style={{ color: "red" }}>{fetchError}</p>}
        {isLoading === true && fetchError == null  ?  <Main /> : <p className="statusMsg">No posts to display.</p>}  */}
         
        </Grid>
      </Grid>
    
    </>
  );
};
export default Home;
