import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/home";
import Welcome from "./components/Share/Welcome";
import ProductDetailPage from "./components/Main/ProductDetailPage";
import { DataProvider } from "./context/DataContext";
import PageNotFound from "./components/Share/PageNotFound";

const App = () => {
  
  const urlPath = window.location.pathname.split('/');
 
  let storeName= urlPath[1];
  let storeId = 0;// urlPath[3];
 
  
  return (
    <div>
      <DataProvider  storeName={storeName} storeId={0}>
     
      <Routes>
        <Route path="/" element={<Welcome />} />
        
        <Route path="/:Store" element={<Home />}  />

        <Route
          path="/:Store/:id" exact
          element={<ProductDetailPage />}
        />
         <Route path="*" element={<PageNotFound />} />
         <Route path="/pagenotfound" element={<PageNotFound />} />
      </Routes>
     
      </DataProvider>
    </div>
  );
};

export default App;
